<template>
  <div id="sidebar" v-if="this.$route.meta.showSidebar">
    <div class="user-info">
      <div class="user-img">
        <img src="@/assets/images/logoGrupoNsc.png" alt="User image">
      </div>
      <!-- Menu dropdown here -->
    </div>
    <div id="nav-menu" class="links">
      <navigation-icon icon="fas fa-home" destination="home" label="Home" />
      <navigation-icon icon="fas fa-user-friends" destination="customers" label="Clientes" />
      <navigation-icon icon="fas fa-hammer" destination="templates" label="Templates" />
      <navigation-icon icon="fas fa-users-cog" destination="settings" label="Configurações" />
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar"
};
</script>