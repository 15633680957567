<template>
  <div class="w-100 object-table">
    <div class="object-table-header">
      <span>Valores</span>      
    </div>
    <div class="object-table-body">
      <div class="object-table-row" v-for="(val, i) in parsedValue" :key="i">
        <button
          class="bg-warning text-white mr-1"
          :class="{'cursor-block disabled': i == 0}"
          @click="i > 0 ? orderUp(i) : () => {}" 
        >
          <i class="fas fa-chevron-up"></i>
        </button>
        <button
          class="bg-info text-white mr-1"
          :class="{'cursor-block disabled': i == (parsedValue.length - 1)}"
          @click="i == (parsedValue.length - 1) ? () => {} :orderDown(i)"
        >
          <i class="fas fa-chevron-down"></i>
        </button>
        <div class="obect-table-item d-flex b-0">
          <input
            @input="updateFieldValue($event, i)"
            :value="val"
            type="text"
            placeholder="Valor"
            class="b-0"
            style="border-right: 0px;"
          >
          <div class="d-flex flex-row" style="background-color: #15202b;" v-if="hasCryptoList">
            <div class="input-group-append ml-0">
              <span class="input-group-text">
                <i @click="eventoListaCrypto()" class="fas fa-lock"></i>
              </span>
            </div>
            <div class="input-group-append ml-0">
              <span class="input-group-text">
                <i @click="eventoListaDescrypto()" class="fas fa-unlock"></i>
            </span>
           </div>
           <div class="input-group-append ml-0">
              <span class="input-group-text">
                <i @click="alterarTodosBotaoClickArray()" class="fas fa-list-alt"></i>
            </span>
           </div>
        </div>


        </div>
        <button
          class="bg-danger text-white ml-1"
          @click="removeRow(i)"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="object-table-row">
        <div class="obect-table-item">
          <button @click="addRow">
            Nova linha
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-array',
  data: () => ({}),
  computed: {
    parsedValue() {
      return JSON.parse(this.value)
    }
  },
  methods: {
    alterarTodosBotaoClickArray() {
      this.$emit('alterarTodosBotaoClickArray')
    },
    updateFieldValue(evt, index) {
      let value = evt.target.value
      let obj = [...this.parsedValue]
      obj[index] = value
      let stringfy = JSON.stringify(obj)
      this.$emit('input', stringfy)
    },
    addRow(i) {
      let obj = [...this.parsedValue]
      obj.push('')
      let stringfy = JSON.stringify(obj)
      this.$emit('input', stringfy)
    },
    removeRow(i) {
      let obj = [...this.parsedValue]
      obj.splice(i, 1)
      let stringfy = JSON.stringify(obj)
      this.$emit('input', stringfy)
    },
    orderUp(i) {
      let obj = [...this.parsedValue]
      let current = obj[i]
      let aux = obj[i-1]
      obj[i] = aux
      obj[i-1] = current
      let stringfy = JSON.stringify(obj)
      this.$emit('input', stringfy)
    },
    orderDown(i) {
      let obj = [...this.parsedValue]
      let current = obj[i]
      let aux = obj[i+1]
      obj[i] = aux
      obj[i+1] = current
      let stringfy = JSON.stringify(obj)
      this.$emit('input', stringfy)
    },
    eventoListaCrypto() {
      this.$emit('eventoListaCrypto')
    },
    eventoListaDescrypto() {
      this.$emit('eventoListaDescrypto')
    }
  },
  props: {
    value: {
      type: [String],
      description: 'Input value',
      default: '[]'
    },
    hasCryptoList: {
      type: Boolean,
      description: 'Crypto icons',
      default: false,
      required: false
    }
  }
}
</script>


