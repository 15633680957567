// Dependencias.
import Vue from 'vue';

// Estado inicial
const initialState = () => ({
  customers: [],
  total: 0,
  loadingState: false,
});

// Objeto de estado
const state = initialState();

// Getters
const getters = {
  getCustomerList(state) {
    return state.customers;
  },

  getCustomersListToSelectInput(state) {
    return state.customers.map(customer => ({
      desc: customer.identificacao,
      value: customer.identificacao,
    }));
  },

  getTotal(state) {
    return state.total;
  },

  getLoadingState(state) {
    return state.loadingState;
  },
};

// Ações
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },
  createCustomer({ commit }, { name, identifier, cnpjCpf, city, state, description, logo, statusCustomer }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/clientes`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'POST',
        {
          nome: name,
          identificacao: identifier,
          cnpjCpf: cnpjCpf,
          uf: state,
          cidade: city,
          descricao: description,
          logotipo: logo,
          statusCliente: statusCustomer
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Cliente incluído com sucesso.',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },
  fetchCustomers({ commit }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/clientes`;
      commit('setLoadingState', true);
      const response = await api.send(url, 'GET', null, undefined, false);
      commit('setLoadingState', false);
      if (response.status == 200) {
        commit('setCustomers', response.data.dados);
        commit('setTotal', response.data.dados.length);
        resolve({
          status: 200,
          msg: 'Lista de clientes recuperada.',
        });
      } else {
        commit('setCustomers', []);
        commit('setTotal', 0);
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },
  updateCustomer({ commit }, { id, name, identifier, cnpjCpf, city, state, description, logo, statusCustomer }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/clientes`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'PUT',
        {
          id: id,
          nome: name,
          identificacao: identifier,
          cnpjCpf: cnpjCpf,
          uf: state,
          cidade: city,
          descricao: description,
          logotipo: logo,
          statusCliente: statusCustomer
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Cliente atualizado com sucesso.',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },
};

// Mutações
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setCustomers(state, data) {
    state.customers = data;
  },
  setTotal(state, data) {
    state.total = data;
  },
  setLoadingState(state, data) {
    state.loadingState = data;
  },
};

// Exoprtanto módulo
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
