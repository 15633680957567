// Dependencies.
import Vue from 'vue'
import App from './App.vue'
import plugins from './plugins'
import router from './router'
import store from './store'

// Production tips.
Vue.config.productionTip = false

// Vue instance.
const initApp = () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

// Adding plugins.
Vue.use(plugins)

// Starting app.
initApp()
