// Dependencias.
import Vue from 'vue';

// Estado inicial
const initialState = () => ({
  templates: [],
  total: 0,
  loadingState: false,
});

// Objeto de estado
const state = initialState();

// Getters
const getters = {
  getTemplateList(state) {
    return state.templates;
  },
  getTotal(state) {
    return state.total;
  },
  getLoadingState(state) {
    return state.loadingState;
  },
};

// Ações
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  createTemplate({ commit }, { application, name, description, entrances }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'POST',
        {
          aplicacao: application,
          nome: name,
          descricao: description,
          entradas: entrances,
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Template criado com sucesso!',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchTemplates({ commit }, { filter }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates${filter ? `?filtroAplicacao=${filter}` : ''}`;

      commit('setLoadingState', true);

      const response = await api.send(url, 'GET', null, undefined, false);

      commit('setLoadingState', false);

      if (response.status == 200) {
        // Verificando se o template tem ordem, caso não tenha, vamos setar manualmente pelo index do elemento no array
        const templatesComOrdem = [...response.data.dados];

        templatesComOrdem.forEach(template => {
          template.entradas.forEach((entrada, index) => {
            if (entrada.ordem === undefined) {
              entrada.ordem = index;
            }
          });

          template.entradas.sort((entradaA, entradaB) => entradaA.ordem - entradaB.ordem);
        });

        commit('setTemplates', templatesComOrdem);
        commit('setTotal', response.data.dados.length);

        resolve({
          status: 200,
          msg: 'Lista de templates recuperada.',
        });
      } else {
        commit('setTemplates', []);
        commit('setTotal', 0);

        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  removeTemplate({ commit }, { id }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates/${id}`;
      commit('setLoadingState', true);
      const response = await api.send(url, 'DELETE', null, undefined, false);
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: `Template de id ${id} removido com sucesso!`,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  removeTemplateEntrance({ commit }, { id, key }) {
    return new Promise(async resolve => {
      //'
      const api = new Vue.prototype.$httpClient();
      const url = `/templates/${id}/entradas/${key}`;
      commit('setLoadingState', true);
      const response = await api.send(url, 'DELETE', null, undefined, false);
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: `Entrada ${key} foi removida do template de id ${id} com sucesso!`,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  updateDescription({ commit }, { id, description }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'PUT',
        {
          id: id,
          descricao: description,
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Descrição do template alterada com sucesso.',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  updateEntrance({ commit }, { id, key, value, description, type, enabled, clonable, ordem }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates/${id}/entradas`;
      commit('setLoadingState', true);
      const corpo = {
        chave: key,
        descricao: description,
        valorPadrao: value,
        tipo: type,
        clonavel: clonable,
        ordem: ordem,
      };

      console.log(corpo);

      const response = await api.send(url, 'POST', corpo, undefined, false);
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: enabled
            ? `Entrada de chave ${key} do template de id ${id} criada com sucesso!`
            : `Entrada de chave ${key} do template de id ${id} salvo com sucesso!`,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  orderEntrances({ commit }, { id, entrances }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/templates/${id}/entradas/ordenar`;
      commit('setLoadingState', true);

      const response = await api.send(
        url,
        'POST',
        {
          id: id,
          entradas: entrances,
        },
        undefined,
        false
      );

      commit('setLoadingState', false);

      if (response.data.statusCode == 200) {
        resolve({ status: 200, msg: 'Entradas ordenadas com sucesso' });
      } else {
        resolve({ status: response.data.statusCode, msg: response.data.mensagem });
      }
    });
  },
};

// Mutações
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  setTemplates(state, data) {
    state.templates = data;
  },
  setTotal(state, data) {
    state.total = data;
  },
  setLoadingState(state, data) {
    state.loadingState = data;
  },
};

// Exoprtanto módulo
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
