const CryptoJS = require("crypto-js");

class CryptoAWS {

    constructor() {
    }

    static encrypto(text) {
        return CryptoJS.AES.encrypt(text, 'secretkeynsc').toString();
    }

    static decrypt(ciphertext) {
        var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secretkeynsc');
        return bytes.toString(CryptoJS.enc.Utf8);
        
    }

    static encryptObject(textObject) {
        return CryptoJS.AES.encrypt(JSON.stringify(textObject), 'secretkeynsc').toString();
    }

    static decryptObject(textObject) {
        const bytes  = CryptoJS.AES.decrypt(textObject, 'secretkeynsc');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
}

export default CryptoAWS