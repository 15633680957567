<template>
  <div id="pre-content">
    <div id="content" class="content-wrapper card">
      <b-container>
        <!-- Top component -->
        <div class="row">
          <div class="col-12">
            <h1 class="text-white text-uppercase">Bem-vindo ao configurador!</h1>
            <hr />
            <p class="text-white">
              Abaixo seguem algumas breves instruções para bem utilizar essa interface:
            </p>
            <ul class="home-ul">
              <li>
                <p class="text-white">
                  Nesse dashboard você encontrará uma interface para gerenciar Clientes, Templates e Configurações de 
                  aplicações desenvolvidas pela NSC, segue abaixo uma pequena introdução sobre como cada módulo se comporta:
                </p>
              </li>
              <li>
                <p class="text-white">
                  O módulo de <strong>Clientes</strong> serve para você cadastrar, alterar e visualizar Clientes cadastrados 
                  para as aplicações, o campo <strong>Identificação</strong> presente em seu cadastro servirá como identificador em 
                  configurações criadas.
                </p>
              </li>
              <li>
                <p class="text-white">
                  Já nos <strong>Templates</strong> você cadastrará um modelo padrão para as configurações, ou seja, 
                  as configurações serão criadas a partir de templates, e para adicionar um campo novo a todas as configurações 
                  pertencentes a um tipo basta adiciona-lá ao template que o processo será automático. O mesmo vale para remoção de campos. 
                </p>
              </li>
              <li>
                <p class="text-white">
                  Nas <strong>Configurações</strong> serão armazenadas os valores das configurações em si, a partir delas o app será renderizado 
                  condicionalmente, tome os devidos cuidados durante a alterãção, pois implicarão diretamente no funcionamento dos apps que as usam.
                </p>
              </li>
            </ul>
            <p class="text-white">
              Após essas instruções breves, explore a vontade, esse  projeto foi desenvolvido para facilitar o processo de configurações :)
            </p>  
          </div>
        </div>
        <!-- First row -->
        <div class="row">
          <div class="col-12">
            <h1 class="text-white text-uppercase">Atalhos!</h1>
            <hr />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <card-clientes
              :customers="customers"
            />
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import CardClientes from './cards/CardClientes'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'home',
  beforeMount() {
    this.loadCustomers({}).then(result => {
      if (![200, 201, 404].includes(result.status)) {
        this.$swal({
          type: 'error',
          title: 'Ops...',
          text: result.msg
        })
      }
    })
  },
  computed: {
    ...mapGetters({
      customers: 'clientes/getCustomerList'
    })
  },
  components: { CardClientes },
  methods: {
    ...mapActions({
      loadCustomers: 'clientes/fetchCustomers'
    })
  }
};
</script>