export const BRASIL_STATES = [
  {
    value: "AC",
    desc: "Acre"
  },
  {
    value: "AL",
    desc: "Alagoas"
  },
  {
    value: "AM",
    desc: "Amazonas"
  },
  {
    value: "AP",
    desc: "Amapá"
  },
  {
    value: "BA",
    desc: "Bahia"
  },
  {
    value: "CE",
    desc: "Ceará"
  },
  {
    value: "DF",
    desc: "Distrito Federal"
  },
  {
    value: "ES",
    desc: "Espírito Santo"
  },
  {
    value: "GO",
    desc: "Goiás"
  },
  {
    value: "MA",
    desc: "Maranhão"
  },
  {
    value: "MG",
    desc: "Minas Gerais"
  },
  {
    value: "MS",
    desc: "Mato Grosso do Sul"
  },
  {
    value: "MT",
    desc: "Mato Grosso"
  },
  {
    value: "PA",
    desc: "Pará"
  },
  {
    value: "PB",
    desc: "Paraíba"
  },
  {
    value: "PE",
    desc: "Pernambuco"
  },
  {
    value: "PI",
    desc: "Piauí"
  },
  {
    value: "PR",
    desc: "Paraná"
  },
  {
    value: "RJ",
    desc: "Rio de Janeiro"
  },
  {
    value: "RN",
    desc: "Rio Grande do Norte"
  },
  {
    value: "RO",
    desc: "Rondônia"
  },
  {
    value: "RR",
    desc: "Roraima"
  },
  {
    value: "RS",
    desc: "Rio Grande do Sul"
  },
  {
    value: "SC",
    desc: "Santa Catarina"
  },
  {
    value: "SE",
    desc: "Sergipe"
  },
  {
    value: "SP",
    desc: "São Paulo"
  },
  {
    value: "TO",
    desc: "Tocantins"
  }
];