<template>
  <div v-if="customers.length" class="card bg-secondary p-3">
    <div class="row" id="customer-card">
      <div class="col-12 mb-2">
        <p class="m-0 text-white">Configurações de Clientes</p>
        <hr class="mb-1 mt-1"/>
      </div>
      <div
        v-for="(c, i) in customers"
        :key="i"
        @click="navigateTo('filteredSettings', c.identificacao)"
        class="col-12 d-flex justify-content-between align-items-center mb-2 client-redirect"
      >
        <p class="m-0 text-white">
          <strong>{{ c.nome }}</strong>
        </p>
        <span class="text-white">
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'customers',
  props: {
    customers: {
      type: Array,
      description: 'Customers list for redirect',
      default: []
    }
  },
  methods: {
    navigateTo(route, filter) {
      this.$router.push({ 
        name: route, 
        params: { pathMatch: filter }
      })
    }
  }
}
</script>