<template>
  <div class="w-100 object-table">
    <div class="object-table-header">
      <span v-for="key in parsedKeys" :key="key" :class="{ 'row_input_tipo': key === 'tipo' }">{{ key }}</span>
    </div>
    <div class="object-table-body">
      <div class="object-table-row" v-for="(obj, i) in parsedValue" :key="i">
        <button class="bg-warning text-white mr-1" :class="{ 'cursor-block disabled': i == 0 }"
          @click="i > 0 ? orderUp(i) : () => { }" v-if="!readOnly">
          <i class="fas fa-chevron-up"></i>
        </button>
        <button class="bg-info text-white mr-1" :class="{ 'cursor-block disabled': i == parsedValue.length - 1 }"
          @click="i == parsedValue.length - 1 ? () => { } : orderDown(i)" v-if="!readOnly">
          <i class="fas fa-chevron-down"></i>
        </button>
        <div class="obect-table-item" :class="{ 'row_input_tipo': key === 'tipo' }" v-for="key in parsedKeys"
          :key="key">
          <base-colorpicker v-if="key == 'cor'" class="mb-0 w-100 table-color" :color="getStringValue(i, key)"
            :placeholder="getStringValue(i, key)" :disabled="readOnly" @input="updateFieldValue($event, i, key)" />
          <div v-else-if="key == 'image'" class="image-icon">
            <i @click="openImage(getStringDefaultValue(i, key))" class="far fa-image collapse-clientes-listagem"></i>
            <!-- <img :src="getStringDefaultValue(i, key)" alt="" style="max-width: 100%"> -->
          </div>
          <div class="d-flex" v-else>
            <!-- CASO STRING INPUT PADRAO -->
            <input v-if="(!['cor', 'imagem', 'boolean'].includes(obj.tipo) || key !== 'valor') && key !== 'tipo'"
              @input="updateFieldValue($event, i, key)" :value="getStringValue(i, key)" type="text" :disabled="readOnly"
              :placeholder="key" class="border-right-0" style="min-height: 40px" />

            <input v-if="key == 'tipo'" @input="updateFieldValue($event, i, key)" :disabled="tipoObjeto == 'multitable'"
              :value="getStringValue(i, key)" type="text" :placeholder="key" class="border-right-0"
              style="min-height: 40px" />
            <!-- FIM CASO STRING INPUT PADRAO -->

            <!-- CASO TIPO IMAGEM -->
            <!-- INPUT INVISIVEL -->
            <input @change="onImageChange($event, obj, i)" type="file"
              :id="`${obj.chave}-${configName}-realImageButton`" hidden="hidden" :disabled="readOnly"
              accept="image/x-png, image/jpeg" />
            <!-- FIM INPUT INVISIVEL -->

            <base-button v-if="(obj.tipo === 'imagem' || obj.tipo === 'image') && key === 'valor' && !readOnly"
              @click="clicarNoInputHidden(`${obj.chave}-${configName}-realImageButton`)" :disabled="readOnly"
              class="overflow-text" style="
                background-color: #15202b;
                border-radius: unset;
                min-height: 40px;
                margin-inline: 2px;
                width: 70px;
                height: 40px;
              ">
              <i class="fas fa-paperclip ml-1"></i>
            </base-button>

            <base-input v-if="key === 'valor' && obj.tipo === 'imagem'" class="mb-0 w-100 mt-0" :disabled="readOnly"
              @input="updateFieldValue($event, i, key)" v-tooltip.top="tooltipContent" v-model="obj.valor"
              :placeholder="obj.valor" @mouseover="updateTooltipImage(obj)"></base-input>
            <!-- FIM CASO TIPO IMAGEM -->

            <!-- CASO TIPO COR -->
            <base-colorpicker v-if="obj.tipo === 'cor' && key === 'valor'" class="mb-0 w-100 table-color"
              :readOnly="readOnly" style="background-color: #15202b; border-right: 0px;" :key="obj.valor"
              :color="getStringValue(i, key)" :placeholder="getStringValue(i, key)"
              @input="updateFieldValue($event, i, key)" />
            <!-- FIM CASO TIPO COR -->

            <!-- CASO TIPO BOOLEAN -->

            <base-checkbox v-if="obj.tipo === 'boolean' && key === 'valor'" class="mb-0 w-100" :disabled="readOnly"
              v-model="obj.valor" @change="updateFieldValue($event, i, key, obj)" @focus="forcarAtualizacao"
              @blur="forcarAtualizacao" addonRightIcon :placeholder="obj.chave" />
            <!-- FIM CASO TIPO BOOLEAN -->

            <!-- SELECT DE TIPO -->
            <base-select :options="typeOptions" :readOnly="readOnly" @change="updateFieldValue($event, i, key, obj)"
              v-if="key === 'tipo' && !readOnly && tipoObjeto == 'multitable'" style="width: 20px" />
            <!-- FIM SELECT DE TIPO -->

            <div class="d-flex flex-row color-icon" v-if="hasCrypt">
              <div v-if="obj.tipo == 'string' && key === 'valor' && !readOnly" class="input-group-append ml-0">
                <span class="input-group-text">
                  <i @click="eventoCriptografar(obj)" :disabled="readOnly" class="fas fa-lock"></i>
                </span>
              </div>

              <div v-if="obj.tipo == 'string' && key === 'valor' && !readOnly" class="input-group-append ml-0 mr-1">
                <span class="input-group-text">
                  <i @click="eventoDescriptografar(obj)" :disabled="readOnly" class="fas fa-unlock"></i>
                </span>
              </div>
              <!-- <div class="input-group-append ml-0 mr-1" v-if="!readOnly">
                <span class="input-group-text">
                  <i
                    @click="alterarTodosBotaoClickObject()"
                    :disabled="readOnly"
                    class="fas fa-list-alt"
                  ></i>
                </span>
              </div> -->
            </div>
          </div>
        </div>
        <button class="bg-danger text-white ml-1" :class="{ 'cursor-block': parsedValue.length <= 1 }"
          @click="parsedValue.length > 1 ? removeRow(i) : () => { }" v-if="!readOnly">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="object-table-row">
        <div class="obect-table-item">
          <button @click="addRow" v-if="!readOnly">Nova linha</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { readonly } from 'vue';

export default {
  name: 'base-object',
  data: () => ({
    typeOptions: [
      {
        value: 'cor',
        desc: 'cor',
      },
      {
        value: 'imagem',
        desc: 'imagem',
      },
      {
        value: 'string',
        desc: 'string',
      },
      {
        value: 'boolean',
        desc: 'boolean',
      },
    ],
    tooltipContent: '',
  }),

  methods: {
    alterarTodosBotaoClickObject() {
      this.$emit('alterarTodosBotaoClickObject');
    },
  },

  mounted: function () {
    // this.parsedKeys.forEach((key) => console.log(key))
    // console.log(this.parsedValue);
  },

  watch: {
    parsedValue: {
      handler(newVal) {
        newVal.forEach(obj => {
          if (obj.tipo === 'boolean' && obj.valor === '') {
            obj.valor = false; // Define como false se o valor for uma string vazia
          }
        });
      },
      deep: true, // Necessário para observar mudanças profundas no objeto
      immediate: true, // Executa o watch na primeira renderização
    },
  },

  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    parsedValue() {
      return JSON.parse(this.value);
    },
    parsedKeys() {
      const exObj = this.parsedValue.length ? this.parsedValue[0] : {};
      return Object.keys(exObj).map(k => k);
    },
    isProd() {
      return (
        location.href.startsWith(process.env.PROD_URL) ||
        location.href.replace('http://', 'https://').startsWith(process.env.PROD_URL)
      );
    },
  },
  methods: {
    updateTooltipImage(config) {
      this.tooltipContent = `
        <div style="text-align: center;">
          <img src="${config.valor}" alt="Descrição da imagem" style="width: 100px; height: auto;" />
        </div>
      `;
    },
    clicarNoInputHidden(elementId) {
      document.getElementById(elementId).click();
    },

    forcarAtualizacao() {
      this.$forceUpdate();
    },

    achaAlgo(aqui) {
      this.currentConfig.find(e => e.entradas === aqui);
    },

    async onImageChange(event, config, index) {
      console.log('chorei', config, index, this.parsedValue, this.currentConfig);
      AWS.config.update({
        accessKeyId: this.isProd ? process.env.AWS_ACCESSKEYID : process.env.AWS_ACCESSKEYID_TEST,
        secretAccessKey: this.isProd ? process.env.AWS_SECRETACCESSKEY : process.env.AWS_SECRETACCESSKEY_TEST,
      });

      const bucket = this.isProd ? process.env.AWS_BUCKET : process.env.AWS_BUCKET_TEST;

      const bucket_pasta = this.currentConfig.cliente + '/';

      const bucket_sub_pasta = 'imagens/';

      const imageId = uuidv4();

      const s3 = new AWS.S3();

      const reader = new FileReader();

      reader.onload = async () => {
        // Tipo da imagem
        const tipo = reader.result.split(';')[0].split('/')[1];

        // Base64 sem aquele começinho pra falar qual o tipo da imagem
        const dadosBase64 = new Buffer.from(reader.result.replace(/^data:image\/\w+;base64,/, ''), 'base64');

        // Parâmetro da AWS
        const params = {
          Bucket: bucket,
          Body: dadosBase64,
          Key: `${bucket_pasta}${bucket_sub_pasta}${imageId}.${tipo}`,
          ContentType: `image/${tipo}`,
          ContentEncoding: 'base64',
          ACL: 'public-read',
        };

        // Upload pro S3
        const responseS3 = await s3.upload(params).promise();

        // Pegando a URL
        const urlImagem = responseS3.Location;

        // E colocando ela no campo

        const chave = this.parsedValue.findIndex(obj => obj.chave === config.chave);
        config.valor = urlImagem;
        this.parsedValue[chave] = config;

        this.updateFieldValue(urlImagem, index, 'valor');
        this.forcarAtualizacao();
      };
      reader.readAsDataURL(event.target.files[0]);
    },

    eventoCriptografar(config) {
      this.$emit('eventoCriptografar', { component: 'base-object', config: config });
    },
    eventoDescriptografar(config) {
      this.$emit('eventoDescriptografar', { component: 'base-object', config: config });
    },
    updateFieldValue(evt, index, key, linha) {
      let value = evt.target ? evt.target.value : evt;

      if (value === 'boolean' && typeof linha.valor != 'boolean' && linha.valor != 'true') {
        linha.valor = 'false';
      }

      let obj = [...this.parsedValue];
      obj[index][key] = value;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
      this.$forceUpdate();
    },
    getStringValue(index, key) {
      const obj = this.parsedValue[index];
      return obj[key];
    },
    getStringDefaultValue(index, key) {
      let parsedDefaultValue = JSON.parse(this.defaultValue);
      const obj = parsedDefaultValue[index];
      return obj[key];
    },
    addRow(i) {
      let obj = [...this.parsedValue];
      let newO = {};
      this.parsedKeys.forEach(key => {
        newO[key] = '';
      });
      obj.push(newO);
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    removeRow(i) {
      let obj = [...this.parsedValue];
      obj.splice(i, 1);
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    orderUp(i) {
      let obj = [...this.parsedValue];
      let current = obj[i];
      let aux = obj[i - 1];
      obj[i] = aux;
      obj[i - 1] = current;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    orderDown(i) {
      let obj = [...this.parsedValue];
      let current = obj[i];
      let aux = obj[i + 1];
      obj[i] = aux;
      obj[i + 1] = current;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    openImage(img) {
      this.$swal({
        imageUrl: img,
      });
    },
  },
  props: {
    value: {
      type: [String],
      description: 'Input value',
      default: '[]',
    },
    defaultValue: {
      type: [String],
      description: 'Input value',
      default: '[]',
    },
    hasCrypt: {
      type: Boolean,
      description: 'Addon right icon',
      default: false,
      required: false,
    },
    currentConfig: {
      type: Object,
      description: 'Current client config',
      default: {},
      required: false,
    },
    configName: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      description: 'Read only mode',
      required: false,
      default: false,
    },
    tipoObjeto: {
      type: String,
      required: true,
    }
  },
};
</script>

<style scoped>
input {
  font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.row_input_tipo {
  max-width: 8%;
}

.color-icon {
  background-color: #15202b;
}

.color-picker {
  background-color: #15202b;
  color: #fff;
  outline: none;
  border: none;
  border-left: 2px solid #ffffff20;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
</style>
