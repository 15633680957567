import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'
import VueGoodTablePlugin from 'vue-good-table';

import { HttpClient } from '../lib/HttpClient'
import { Text } from '../lib/Helper'
import {CryptoAWS} from '../lib/Crypto';

import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-good-table/dist/vue-good-table.css'


export default {
  install (Vue) {
    Vue.use(BootstrapVue)
    Vue.use(VueSweetalert2)
    Vue.use(globalComponents)
    Vue.use(globalDirectives)
    Vue.use(Vuelidate)
    Vue.use(VTooltip)
    Vue.use(VueGoodTablePlugin);
    Vue.prototype.$httpClient = HttpClient
    Vue.prototype.$text = Text
    Vue.prototype.$crypto = CryptoAWS
  }
}
