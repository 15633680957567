<template>
  <div id="dev-warning" class="bg-danger">
    <p class="m-0 text-white">
      <small>
        <strong>Atenção!!! Essa é uma versão de desenvolvimento.</strong>
      </small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'dev-warning'
}
</script>