import BaseArray from '../components/theme/BaseArray'
import BaseButton from '../components/theme/BaseButton'
import BaseCard from '../components/theme/BaseCard'
import BaseCheckbox from '../components/theme/BaseCheckbox'
import BaseColorPicker from '../components/theme/BaseColorPicker'
import BaseDropdown from '../components/theme/BaseDropdown'
import BaseHeader from '../components/theme/BaseHeader'
import BaseInput from '../components/theme/BaseInput'
import BaseList from '../components/theme/BaseList'
import BaseModal from '../components/theme/BaseModal'
import BaseObject from '../components/theme/BaseObject'
import BasePagination from '../components/theme/BasePagination'
import BaseSelect from '../components/theme/BaseSelect'
import BaseTextArea from '../components/theme/BaseTextArea'
import BaseTags from '../components/theme/BaseTags'
import DevWarning from '../components/layout/template/DevWarning'
import Sidebar from '../components/layout/template/Sidebar'
import NavigationIcon from '../components/layout/template/NavigationIcon'
import NavigationHeader from '../components/layout/template/NavigationHeader'

export default {
  install (Vue) {
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCard.name, BaseCard)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseColorPicker.name, BaseColorPicker)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseList.name, BaseList)
    Vue.component(BaseModal.name, BaseModal)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseSelect.name, BaseSelect)
    Vue.component(BaseTextArea.name, BaseTextArea)
    Vue.component(BaseTags.name, BaseTags)
    Vue.component(DevWarning.name, DevWarning)
    Vue.component(Sidebar.name, Sidebar)
    Vue.component(NavigationIcon.name, NavigationIcon)
    Vue.component(NavigationHeader.name, NavigationHeader)
    Vue.component(BaseObject.name, BaseObject)
    Vue.component(BaseArray.name, BaseArray)
  }
}
