<template>
  <router-link
    class="nav-link"
    :to="{ name: destination }"
  >
    <span class="nav-icon text-white">
      <i :class="icon"></i>
    </span>
    <span class="nav-label">{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: "navigation-icon",
  props: {
    icon: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    destination: {
      type: String,
      default: ""
    }
  }
};
</script>