<template>
  <div class="qr-input">
    <div class="form-group d-flex">
      <vue-tags-input
        class="w-100 custom-input-tag"
        v-model="tag"
        autocomplete="off"
        :tags="tags"
        @tags-changed="changedTag"
        :placeholder="placeholder"
        :autocomplete-items="autocompleteItemsFormated"
        :add-only-from-autocomplete="addOnlyFromAutocomplete"
        :max-tags="maxTags"
        :id="id"
      />
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'BaseTags',

  components: {
    VueTagsInput,
  },

  props: {
    autocompleteItems: {
      required: false,
      type: Array,
      default: () => [],
    },

    placeholder: {
      required: false,
      type: String,
      default: () => '',
    },

    defaultItems: {
      required: false,
      type: Array,
      default: () => [],
    },

    maxTags: {
      required: false,
      type: Number,
      default: () => 2,
    },

    addOnlyFromAutocomplete: {
      required: false,
      type: Boolean,
      default: () => false,
    },

    id: {
      required: true,
      type: String,
    },

    clearTags: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    autocompleteItemsFormated() {
      return this.autocompleteItems.map((item) => ({
        text: item,
      }));
    },

    tagsFormatedToComponent() {
      return this.defaultItems.map((item) => ({
        text: item,
      }));
    },

    tagsFormatedToEvent() {
      return this.tags.reduce((prev, curr) => [...prev, curr.text], []);
    },
  },

  data() {
    return {
      tag: '',
      tags: [],
    };
  },

  beforeMount() {
    if (this.tagsFormatedToComponent.length) {
      this.changedTag(this.tagsFormatedToComponent);
    }
  },

  methods: {
    changedTag(newTags) {
      this.tags = newTags;
      this.$emit('input', this.tagsFormatedToEvent);
    },
  },

  watch: {
    clearTags(newValue) {
      if (newValue) {
        this.changedTag(this.tagsFormatedToComponent);
      }
    },
  },
};
</script>

<style lang="scss">
.custom-input-tag {
  width: 100% !important;
  height: 2.5rem;
  max-width: unset !important;
  background-color: transparent !important;
  border-bottom: 1px solid var(--color-secondary);
  display: flex;
  align-items: center;

  .ti-input {
    border: 0 !important;
    padding: 0 !important;
    width: 100%;

    .ti-tags {
      flex-wrap: nowrap;
    }
  }

  .ti-new-tag-input-wrapper {
    padding: 0;

    .ti-new-tag-input {
      background-color: transparent;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      text-transform: uppercase;
      color: #fff;

      &::placeholder {
        color: #fff;
      }
    }
  }

  .ti-autocomplete {
    border-top: 1px solid #ccc !important;
    border-radius: 5px !important;
    top: 50px;

    ul {
      max-height: 200px;
      overflow: auto;
    }
  }
}
</style>