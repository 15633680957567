// Dependencias.
import Vue from 'vue';

// Estado inicial
const initialState = () => ({
  settings: [],
  total: 0,
  loadingState: false,
});

// Objeto de estado
const state = initialState();

// Getters
const getters = {
  getSettingsList(state) {
    return state.settings;
  },
  getTotal(state) {
    return state.total;
  },
  getLoadingState(state) {
    return state.loadingState;
  },
};

// Ações
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  createSettings({ commit }, { application, customer, name, entrances }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'POST',
        {
          aplicacao: application,
          cliente: customer,
          entradas: entrances,
          nome: name,
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Configuração criada com sucesso!',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchSettings({ commit }, { filter }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes/listar`;
      commit('setLoadingState', true);

      const response = await api.send(
        url,
        'POST',
        {
          filtro: filter ? filter : undefined,
        },
        undefined,
        false
      );

      commit('setLoadingState', false);

      if (response.status == 200) {
        commit('setSettings', response.data.dados);
        commit('setTotal', response.data.dados.length);

        resolve({
          status: 200,
          msg: 'Lista de configurações recuperada.',
          dados: response.data.dados,
        });
      } else {
        commit('setSettings', []);
        commit('setTotal', 0);

        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchSettingById({ commit }, { id }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes/${id}`;

      commit('setLoadingState', true);

      const response = await api.send(url, 'GET', null, undefined, false);

      commit('setLoadingState', false);

      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Dados da configuração foram recuperados com sucesso.',
          dados: {
            ...response.data.dados,
          },
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  fetchOtherClientsSettings({ commit }, { filter }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();

      const url = `/configuracoes/listar`;

      commit('setLoadingState', true);

      const response = await api.send(
        url,
        'POST',
        {
          filtro: filter,
        },
        undefined,
        false
      );

      commit('setLoadingState', false);

      if (response.status == 200) {
        resolve({
          status: 200,
          msg: 'Lista de configurações recuperada.',
          dados: response.data.dados,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  removeSettings({ commit }, { id }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes/${id}`;
      commit('setLoadingState', true);
      const response = await api.send(url, 'DELETE');
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: `Configuração de id ${id} removida com sucesso!`,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  updateEntrances({ commit }, { id, entrances }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'PUT',
        {
          id: id,
          entradas: entrances,
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Entradas atualizadas com sucesso!',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  alterarTodasEntradas({ commit }, { nome, aplicacao, chave, valor }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes/alterarTodosClientes`;
      commit('setLoadingState', true);
      const response = await api.send(
        url,
        'PUT',
        {
         nome, aplicacao, chave, valor
        },
        undefined,
        false
      );
      commit('setLoadingState', false);
      if ([200, 201].includes(response.status)) {
        resolve({
          status: 200,
          msg: 'Todas as entradas foram alteradas!',
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },

  clonarConfiguracoes({ commit }, { application, customer, newCustomer }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/configuracoes/clonar`;
      commit('setLoadingState', true);
      const response = await api.send(url, 'POST', {
        aplicacao: application,
        cliente: customer,
        novoCliente: newCustomer,
      });
      commit('setLoadingState', false);
      if (response.status == 201) {
        resolve({
          status: 200,
          msg: response.data.mensagem,
        });
      } else {
        resolve({
          status: response.status,
          msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.',
        });
      }
    });
  },
};

// Mutações
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setSettings(state, data) {
    state.settings = data;
  },

  setTotal(state, data) {
    state.total = data;
  },

  setLoadingState(state, data) {
    state.loadingState = data;
  },
};

// Exoprtanto módulo
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
